.pagination {
  text-align: center;
  padding: 0.3em;
  cursor: default;
  margin-top: 2rem;
}

.pagination a,
.pagination span,
.pagination em {
  padding: 0.2em 0.5em;
}

.pagination .disabled {
  color: #aaaaaa;
}

.pagination .current {
  font-style: normal;
  font-weight: bold;
  color: #43a7f3;
}

.pagination a {
  border: 1px solid #dddddd;
  color: #333;
  text-decoration: none;
}

.pagination a:hover,
.pagination a:focus {
  border-color: #43a7f3;
  background: #43a7f3;
  color: white;
}

.pagination .page_info {
  color: #aaaaaa;
  padding-top: 0.8em;
}

.pagination .previous_page,
.pagination .next_page {
  border-width: 2px;
}

.pagination .previous_page {
  margin-right: 1em;
}

.pagination .next_page {
  margin-left: 1em;
}
