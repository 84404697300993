.menu-item {
  @apply text-gray-400 inline-block no-underline py-2 px-4;
  &.active {
    color: rgb(52, 211, 153);
  }
  &:hover {
    color: rgb(52, 211, 153);
  }
}

.nav-bg {
  @apply bg-gray-900
}

.h-full {
  height: 100%;
}